@import '../../main.scss';
.Tooltip {
  &Trigger {
    width: 12px;
    display: inline-block;

    .Icon {
      font-size: 12px;
      cursor: pointer;
    }
  }
  &Popup {
    cursor: default;
    max-width: 200px;
    padding: 4px 6px;
    box-shadow: $FormField__BoxShadow;
    border: solid 1px $Color__Cloud;
    border-radius: 2px;
    background-color: $Color__Marshmellow;
    font-size: $FontSize__ParagraphSmall;
    color: $Color__Steel;
    overflow-wrap: break-word;
  }
}
