@import '../../../main.scss';

.field.FormReadOnly {
  &:not(.FormField--Error) {
    &.disabled,
    &:not(.disabled) {
      & > label.lsFormFieldLabel,
      & > .FormField__SmallText {
        color: $Color__Steel !important;
        & > .FormField__SmallText--Help {
          color: $Color__Steel !important;
        }
      }
    }
  }
  &.field {
    padding: 0 !important;
    color: $Color__Ink !important;
  }
  .FormField__SmallText--Error {
    color: $Color__Rose !important;
  }
}
.FormReadOnly__Control {
  padding: 4px 0px !important;
  height: 26px !important;
  display: flex !important;
  align-items: center !important;
}
