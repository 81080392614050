$minDaySize: 75px;
$minDayWidth: 50px;
@import '../../main.scss';
@import './calendarDay/CalendarDay';
@import './calendarWeek/CalendarWeek';
@import './calendarMonth/CalendarMonth';
@import './calendarThreeMonth/CalendarThreeMonth';
@import './calendarPopupContent/CalendarPopupContent';

.calendarView {
  height: 100%;
  width: 100%;
  background: $white;
  overflow-y: auto;
  > div {
    height: 100%;
  }
}
