@import '../../../main.scss';

@include setDropdownHeight($FormField__Height);

.lsDropdown {
  @include setCommonInputStyles();
  border-color: $Color__RobinLight1 !important;
  border-radius: 2px !important;
  cursor: pointer !important;
  min-width: 0px !important;
  background: $Color__Marshmellow;
  padding: 9px !important;
  width: 100%;
  .text {
    width: inherit !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 17px;
    font-family: $FontStyle !important;
    font-size: $FontSize__ParagraphLarge !important;
    font-weight: normal !important;
    padding: 0px !important;
    color: $Color__Ink !important;
  }
  &.placeholder:not(.disabled) > .text {
    color: $Color__RobinLight1 !important;
  }
  &:hover,
  &:focus {
    border-color: $Color__RobinDark !important;
  }
  & > input,
  &.caret,
  &.active.visible:hover,
  :focus {
    cursor: pointer !important;
  }
  .caret {
    color: $Color__Steel !important;
    font-size: $FontSize__TableHeading !important;
    margin-right: 0 !important;
  }

  &:focus:not(.icon),
  :focus,
  &.active.visible.search.selection {
    box-shadow: $FormField__BoxShadow !important;
    & .visible.menu.transition {
      margin-top: 2px;
      border: 1px solid $Color__RobinDark !important;
      border-radius: 2px !important;
    }
  }

  .visible.menu.transition {
    min-width: fit-content;
    & > .item {
      border: 0 !important;
      padding: 0px 8px !important;
      color: $Color__Steel !important;

      &:hover,
      &:focus {
        background-color: $Color__RobinLight !important;
      }
      &.selected,
      &.selected:hover,
      &.selected:focus {
        background-color: $Color__RobinLight !important;
        & .text {
          background-color: $Color__RobinLight !important;
          color: $Color__Ink !important;
        }
      }
    }
  }

  &.disabled.ui.search,
  &.disabled {
    border-color: $Color__Concrete !important;
    opacity: 1;
    @include setFormDisabledStyles;
    > .text {
      color: $Color__Concrete !important;
    }
    > i.icon {
      color: $Color__Concrete !important;
    }
    &::placeholder {
      color: $Color__Concrete !important;
    }
  }

  input {
    margin: 0 !important;
    padding: inherit !important;
  }
}
.ui.dropdown {
  border-color: $Color__RobinLight1 !important;
  border-radius: 2px !important;
  &.multiple {
    display: flex;
    padding: 0px !important;
    padding-right: 28px !important;
    & > .text {
      top: -1px !important;
      left: 2px !important;
    }
    & > input.search {
      padding: 0px 8px !important;
      color: $Color__RobinLight1 !important;
    }
    & > i {
      position: absolute;
      right: 8.5px;
    }
    min-height: 26px !important;
    height: fit-content !important;
    align-items: center;
    flex-wrap: wrap;

    .ui.label {
      margin: 0.14285714rem;
    }
    & .ui.label {
      margin: 4px 0px 4px 4px !important;
      max-height: 17px !important;
      background-color: $Color__Robin !important;
      border-radius: 2px !important;
      font-size: $FontSize__ParagraphLarge !important;
      color: $Color__Marshmellow !important;
      font-weight: normal !important;
      display: flex;
      align-items: center;
      & > .delete.icon {
        opacity: 1;
        margin-top: 0.1em;
      }
    }
    &.disabled {
      border-color: $Color__Concrete !important;
      & .ui.label {
        background-color: $Color__RobinLight !important;
        color: $Color__Concrete !important;
        box-shadow: none !important;
      }
    }
    .item.selected:not(:hover):not(:focus) {
      background-color: $Color__Marshmellow !important;
      & .text {
        background-color: $Color__Marshmellow !important;
        color: $Color__Steel !important;
      }
    }
  }
}
