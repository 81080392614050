@import "../../main.scss";

.summaryHeaderRoot {
  .summaryGroup {
    display: flex;
    .summaryTitle {
      white-space: nowrap;
      margin-right: 10px;
      position: relative;
    }
    .summaryValues {
      white-space: nowrap;
      text-overflow: clip;
      > span,
      > div {
        white-space: nowrap;
        display: inline-block;
        /* stylelint-disable */
        .creditRating:not(:first-child):before {
          content: "/";
        }
      }
    }
  }
}
