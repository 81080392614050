@import '../../../main.scss';

.ButtonGroup {
  display: flex;
  white-space: nowrap;
  .Option {
    font-style: $FontStyle;
    font-size: $FontSize__ParagraphLarge;
    height: 26px;
    background-color: $Color__Marshmellow;
    border: 1px solid $Color__Robin;
    color: $Color__Robin;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    &.ui.button {
      margin: -1px;
    }
    @include buttonStateColorStyling($Color__RobinLight, $Color__RobinLight);
    border-radius: 0px;
    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      margin: 0px;
    }

    &:not(:first-child) {
      border-left: none;
    }

    &:last-child {
      margin: 0px;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    &.active {
      background-color: $Color__Robin;
      color: $Color__Marshmellow;
      font-weight: $FontWeight__Normal;
      @include buttonStateColorStyling($Color__RobinDark, $Color__Robin);
    }
  }
}
