@import "../../main.scss";
$filled: $impactGreen;
$unfilled: lighten($gray400, 7.36);

.progress-bar {
  //reset native functionality
  appearance: none;
  flex-grow: 0;
  width: 100%;
  max-height: 8px;
  min-height: 8px;
  margin: 4px 2px;
  color: $filled;
  background-color: $unfilled;

  &::-webkit-progress-bar {
    background-color: $unfilled;
  }

  &::-webkit-progress-value {
    background-color: $filled;
  }

  &::-moz-progress-bar {
    background-color: $filled;
  }

  &::-ms-fill {
    background-color: $filled;
  }
}
