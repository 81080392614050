@import '../../../main.scss';
.FormFieldPopup__Portal {
  background-color: $Color__Marshmellow;
  border: 1px solid $Color__RobinLight1;
  z-index: 1000;
  &.inFocus {
    border: 1px solid $Color__RobinDark;
  }
  width: fit-content;
  height: fit-content;
  box-shadow: $FormField__BoxShadow;
}
