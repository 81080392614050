@import '../../../main.scss';

.lsInput {
  background-color: $Color__Marshmellow;
  height: 26px;
  padding: 4px 8px;
  // padding: 4px 8px 4px 4px;
  border-radius: 2px;
  border: 1px solid $Color__RobinLight1;
  outline: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $FontSize__ParagraphLarge !important;
  &__Icon {
    color: $Color__Tin;
    font-size: 12px !important;
    &--Left {
      margin-left: 0px;
      margin-right: 4px;
    }
  }
  & > input {
    background-color: transparent !important;
    color: $Color__Ink !important;
    font-family: $FontStyle !important;
    font-size: $FontSize__ParagraphLarge !important;
    width: 99%;
    text-overflow: ellipsis;
    border: none !important;
    &::placeholder {
      color: $Color__RobinLight1;
    }
    &:focus {
      outline: 0 !important;
      border: none !important;
    }
    &:disabled {
      color: $Color__Concrete !important;
      border: none !important;
    }
  }
  &--Disabled {
    border-color: $Color__Concrete !important;
    & > input {
      color: $Color__Concrete !important;
      &::placeholder {
        color: $Color__Concrete !important;
      }
    }
    & > .Icon {
      color: $Color__Concrete !important;
    }
  }
  &--Focused:not(.lsInput--Disabled) {
    border: 1px solid $Color__RobinDark;
  }
  &--Icon > input {
    width: calc(100% - 12px);
  }
}
