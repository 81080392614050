.PercentageInputFormField {
  position: relative;
  &--WithPlus {
    /* stylelint-disable a11y/content-property-no-static-value */
    &::before {
      content: '+';
      /* stylelint-enable a11y/content-property-no-static-value */
      position: absolute;
      top: 23%;
      left: 10px;
      z-index: 9;
    }
    &.disabled {
      &::before {
        left: 9px;
      }
    }
  }
  & input {
    text-align: right !important;
  }
}
