@import '../../../main.scss';

%CheckboxPrototype {
  &__Container {
    font-family: $FontStyle;
    display: block;
    position: relative;
    padding: 4px 0px 12px 18px;
    outline: none;
    font-size: 13px;
    color: $Color__Steel;
    & [class$='__Label'] {
      cursor: pointer;
      font-family: $FontStyle;
      color: $Color__Steel;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        color: $Color__Ink;
      }
    }
    & [class$='__Background'] {
      cursor: pointer;
      position: absolute;
      top: 7px;
      left: 0;
      width: 13px;
      height: 13px;
      border: solid 1px $Color__Robin;
      font-size: 13px;
      color: $Color__Steel;
      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }
    & input {
      display: none;
      &:disabled {
        & ~ [class$='__Label'] {
          cursor: default;
          color: $Color__Concrete;
          &:hover {
            color: $Color__Concrete;
          }
          & ~ [class$='__Background'] {
            cursor: default;
            border: solid 1px $Color__Cloud;
          }
        }
        &:checked ~ [class$='__Background'] {
          background-color: $Color__Concrete;
        }
      }
    }
    &.disabledReadable > [class$='__Label'] {
      color: $Color__Ink !important;
    }
  }

  &--Checked {
    position: relative;
    font-size: 13px;
    color: $Color__Ink;
    padding-left: 18px;
    & [class$='__Label'] {
      color: $Color__Ink;
    }
    & [class$='__Background'] {
      background-color: $Color__Robin;
      &:after {
        display: block;
        width: 13px;
        height: 13px;
        font-size: 8px;
        border: solid white;
      }
    }
  }
  &--Errored {
    & [class$='__Background'] {
      border: solid 1px $Color__Rose;
    }
  }
  &--Required {
    & > [class$='__Label']:after {
      color: $Color__Rose;
      content: ' *';
    }
  }
}

.Checkbox__Container {
  @extend %CheckboxPrototype__Container;

  & .Checkbox__Background {
    border-radius: 1px;
  }
  &--Checked {
    @extend %CheckboxPrototype--Checked;

    .Checkbox__Background {
      &:after {
        border-radius: 0px;
        left: 0.4em;
        top: 0.1em;
        width: 0.65em;
        height: 1em;
        border-width: 0 0.25em 0.25em 0;
        transform: rotate(48deg);
        -webkit-transform: rotate(48deg);
        -ms-transform: rotate(48deg);
      }
    }
  }

  &--Errored {
    @extend %CheckboxPrototype--Errored;
  }
  &--Required {
    @extend %CheckboxPrototype--Required;
  }
  &.focused {
    & > input:not(:disabled) ~ .Checkbox__Label {
      color: $Color__Ink !important;
    }
  }
  & input:last-child {
    margin-bottom: 8px !important;
  }
}

.CheckboxGroup {
  & > .Checkbox__Container:not(:last-child) {
    padding-bottom: 0 !important;
    &:first-child {
      margin-top: -4px !important;
    }
  }
}

.Checkbox__Container--Errored {
  padding-bottom: 0 !important;
}
