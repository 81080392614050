@import "../../main.scss";

$iconSize: 24px;

.accordionRowHeader {
  display: flex;
  align-items: center;
  min-height: 40px;
  cursor: pointer;

  &--icon {
    height: 24px;
    width: 24px;
  }
  &--body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
