@import '../../main.scss';

.Breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 12px;
  height: 20px;
  .Breadcrumb {
    margin: 0;
    .TextButton {
      overflow: visible;
      color: $secondaryBlue;
    }
    &__Divider {
      display: inline-block;
      width: 20px;
      text-align: center;
    }
  }
}
