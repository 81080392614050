@import '../../main.scss';

%NoScroll {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.lsDataTableWrapper {
  height: 100%;
  .lsDataTable {
    font-family: $FontStyle;
    font-size: $FontSize__ParagraphLarge;
    min-width: max-content;
    height: 100%;
    .Cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 4px 10px 3px;
      &.right {
        text-align: right;
      }
    }
    .Row,
    .Body .Row,
    .Body a {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(80px, 2fr));
      grid-auto-flow: column;
      grid-template-rows: 1;
      max-height: 26px;
      min-height: 26px;
      border-bottom: 1px solid $Color__Fog;
      color: $Color__Ink !important;
    }
    .Row.Header {
      color: $Color__Steel !important;
      font-size: $FontSize__TableHeading;
      text-transform: uppercase;
      max-height: 52px;
      min-height: 52px;

      > .Cell {
        height: 100%;
        @include textOverflow(2);
        > svg.Icon.svg-inline--fa {
          font-size: $FontSize__TableHeading;
          margin-left: 5px;
        }
        &:not(:hover) > .unsorted {
          display: none;
        }
        &.sortable:hover {
          cursor: pointer;
        }
      }
    }
    .Footer {
      color: $Color__Ink;
      font-weight: $FontWeight__Bold;
      border-bottom: 0px;
    }
  }
  .Body {
    overflow-y: scroll;
    @include noScrollBar;
    .Row {
      &:hover:not(.skeleton) {
        background-color: $Color__RobinLight;
      }
      &.skeleton {
        .Cell {
          width: 100%;
          background-color: $Color__Cloud;
          border-radius: 2px;
          margin: 5px 10px;
          width: 75%;
        }
      }
      &.skeleton.odd .Cell {
        width: 50%;
      }
    }

    .Cell {
      &.null,
      &.null > .children {
        color: $Color__Steel !important;
      }
      &.negative {
        color: $Color__Rose;
      }
      &.dropdown {
        overflow: visible;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
        color: $Color__Ink !important;
        > .control .dropdown {
          margin-left: -10px;
          > .visible.menu.transition {
            max-height: 110px;
          }
        }
        &:not(:hover) > .editButton {
          visibility: hidden;
        }
        &:hover {
          cursor: pointer;
        }
        > .editButton {
          color: $Color__Steel;
          font-size: $FontSize__HelperText;
          font-weight: $FontWeight__Bold;
          padding: 0px 10px;
        }
      }
    }
  }
}
