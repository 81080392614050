@import "../../../main.scss";

.DisplayTable-TableHeader {
  flex: 0 0 auto;
  width: 100%;
  display: block;
  background-color: $gray900 !important;
  text-align: left;
  height: 20px;

  .DisplayTable-TableHeaderRow {
    width: 100%;
    padding-right: 0.7rem;
    display: table;
    table-layout: fixed;
    overflow-y: hidden;
    text-align: left;
    transform-origin: top;
    justify-content: space-between;
    align-items: center;
    height: 20px;

    .DisplayTable-HeaderCell {
      font-size: 12px;
      height: 20px;
    }
  }
}
