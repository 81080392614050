@import "../../../main.scss";

.tabNavigationFrame {
  height: 100%;
  width: 100%;

  .TabNavigationMenuWrapper {
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  &__Content {
    background: $white;
    height: calc(100% - 44px);
  }
}
