@import '../../../../../main.scss';

.MonthYearPicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 12px 0px;
  max-width: 350px;
  margin: auto;
  border-bottom: 2px solid $gray500;

  &__MonthYearHeader {
    color: $darkBlueGray;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }

  &__MonthName {
    font-weight: bold;
  }
}
