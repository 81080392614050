@import '../../../main.scss';

.lsFormGroup {
  margin: 0 !important;
  display: flex;
  align-items: flex-start !important;
  & > .field:not(:first-child) {
    margin-left: 16px;
  }
  .lsFormGroup--Hoverable {
    position: relative;
  }

  &--Lowered {
    margin-bottom: 0 !important;
  }
}
