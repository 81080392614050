@import '../../main.scss';

.LSPopup__Trigger--disabled {
  cursor: default;
}

.LSPopupMenu.visible {
  display: flex !important;
  flex-direction: column;
  margin: 0px !important;
  padding: 4px 0px !important;
  color: $Color__Steel;
  background-color: $Color__Marshmellow;
  border: 1px solid $Color__RobinDark;
  cursor: pointer;

  > * {
    margin: 0px;
    padding: 3px 8px;

    &:hover {
      color: $Color__Ink;
      background-color: $Color__RobinLight;
    }
  }
}
