$minMonthHeaderColumn: ($minDayWidth * 7) + 15px;
.calendarThreeMonth {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;

  &__Header {
    background: $white;
    display: grid;
    font-size: 24px;
    grid-template-columns: repeat(3, minmax($minMonthHeaderColumn, 1fr));
    grid-template-rows: 100%;
    padding-bottom: 8px;
    &Item {
      display: flex;
      align-content: center;
      &--Month {
        margin-left: 8px;
        font-weight: bold;
        margin-right: 8px;
      }
    }
  }
  &__Content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 100%;
    height: 100%;
  }
}
