@import "../../main.scss";

.LSTab {
  .menu {
    border-bottom: 0 !important;
    .item {
      color: $green700 !important;
      padding: 0px 0 4px 0 !important;
      margin: 12px !important;
      @include noSelect();
      @include antialiasing();
      &:not(.active) {
        letter-spacing: 0.15px;
      }
      &.active {
        border-bottom: 0.2857142857rem solid $green200 !important;
      }
      &:active:focus {
        border-bottom: 0 !important;
      }
    }
  }
}
