@import '../../../main.scss';
@import '../checkbox/Checkbox.scss';

.Radio__Container {
  @extend %CheckboxPrototype__Container;

  & .Radio__Background {
    border-radius: 50%;
    position: absolute;
    font-size: 13px;
  }
  &--Checked {
    @extend %CheckboxPrototype--Checked;

    .Radio__Background {
      // Creates custom checkmark upon checked state
      &:after {
        left: 0.235rem;
        top: 0.235rem;
        width: 0.315rem;
        height: 0.315rem;
        border-radius: 50%;
        border: 0.1555rem solid $Color__Marshmellow;
        background-color: $Color__Marshmellow;
      }
    }
  }
  &--Errored {
    @extend %CheckboxPrototype--Errored;
  }
  &:focus,
  &:active {
    & > input:not(:disabled) ~ .Radio__Label {
      color: $Color__Ink !important;
    }
  }
}

.Radio__Container:not(:last-of-type),
.Radio__Container--Errored {
  padding-bottom: 0 !important;
}
