@import '../../../../../main.scss';

.DatePickerPopup-MonthPicker {
  height: 150px;
  width: 278px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto;
  .MonthPicker__Month {
    display: flex;
    color: $darkBlueGray;
    font-size: 16px;
    flex-basis: 33.33%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 33px;
    margin: 2px 0;
    flex-direction: column;
    position: relative;
    // stylelint-disable-next-line
    &:hover,
    &:focus {
      background-color: $blue700;
      color: $white;
      opacity: 1;
      outline: none;
      &::before {
        background-color: $blue700;
      }
    }
    &--PickingThreeMonths {
      &:hover,
      &:focus {
        background-color: $secondaryBlue;
        color: $white;
        &::before {
          background-color: $secondaryBlue;
        }
      }
    }
    &:not(&--Selected) {
      cursor: pointer;
    }
    &--Current {
      background-color: $white;
      color: $primaryGreen;
      font-weight: bold;
      &::before {
        content: '';
        height: 4px;
        background-color: $primaryGreen;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &--Selected,
    &--Selected:hover,
    &--Selected:focus,
    &--SelectedNeighbor,
    &--SelectedNeighbor:hover,
    &--SelectedNeighbor:focus {
      background-color: $secondaryBlue;
      color: $white;
      font-weight: bold;
      &::before {
        background-color: $secondaryBlue;
      }
    }
    &--Highlighted {
      color: $white;
      background-color: $blue700;
      border: none;
      box-sizing: border-box;
      &::before {
        background-color: $blue700;
      }
    }
  }
}
