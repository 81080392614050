@import '../../../main.scss';

.LSTextArea {
  font-family: $FontStyle !important;
  font-size: $FontSize__ParagraphLarge !important;
  &::placeholder {
    color: $Color__RobinLight1;
  }
  border: 1px solid $Color__RobinLight1 !important;
  padding: 5px !important;
  background-color: $Color__Marshmellow;
  border-radius: 2px;
  color: $Color__Ink !important;
  min-height: 28px;
  max-width: 100%;
  max-height: 100%;
  &:focus {
    outline: none !important;
    border: 1px solid $Color__RobinDark !important;
  }

  &:disabled,
  &:disabled {
    pointer-events: auto !important;
    border: 1px solid $Color__Concrete !important;
    color: $Color__Concrete !important;
    &::placeholder {
      color: $Color__Concrete !important;
    }
  }
}
