@import "../../../main.scss";

.DisplayTable-TableRowCell {
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  padding: 2px 5px 2px 13px;
  color: $black;

  &--WithRoute {
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .DisplayTable-TableRowCell__Anchor {
    width: 100%;
    height: 31px;
    display: flex;
    align-items: center;
    padding: $displayTableCellPadding;
  }
}
