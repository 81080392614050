@import '../../../main.scss';
.LSButton {
  box-sizing: content-box;
  min-width: 16px;
  min-height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $FontWeight__Normal;
  color: $Color__LoanstreetGreen;
  font-family: $FontStyle;
  font-size: $FontSize__ParagraphLarge;
  border-width: 1px;
  border-radius: 2px;
  &:focus {
    outline: none;
    &,
    :hover:not(:disabled) {
      transition: background-color 0.3s ease;
    }
  }
  &:disabled,
  &.loading {
    cursor: default;
  }
  &__Icon {
    font-size: $FontSize__H3;
    padding: 4px;
  }
  &.label {
    min-width: 48px;
    width: max-content;
    & .LSButton__Label {
      box-sizing: content-box;
      padding: 4px 12px;
    }
    &.icon {
      justify-content: space-between;
      & .LSButton__Icon {
        padding-left: 7px;
      }
      & .LSButton__Label {
        padding-left: 4px;
      }
    }
  }

  &.Primary {
    color: $Color__Marshmellow;
    background-color: $Color__LoanstreetGreen;
    &:not(:disabled):not(.loading) {
      &:hover {
        background-color: $Color__LoanstreetGreenDark;
      }
      &:active {
        background-color: $Color__LoanstreetGreenDark1;
      }
    }
    &:disabled {
      color: $Color__Concrete;
      background-color: $Color__Cloud;
    }
  }
  &.Secondary {
    background-color: $Color__Marshmellow;
    border: solid 1px $Color__LoanstreetGreen;
    &:not(:disabled):not(.loading) {
      &:hover {
        background-color: $Color__Cloud;
        color: $Color__LoanstreetGreenDark;
        border-color: $Color__LoanstreetGreenDark;
      }
      &:active {
        background-color: $Color__Concrete;
        color: $Color__LoanstreetGreenDark1;
        border-color: $Color__LoanstreetGreenDark1;
      }
    }

    &:disabled {
      color: $Color__Concrete;
      background-color: $Color__Cloud;
      border-color: $Color__Concrete;
    }
  }
  &.loading {
    transition: all 0s linear, opacity 0.1s ease;

    & > * {
      visibility: hidden;
    }
    position: relative;
    &:before {
      position: absolute;
      content: '';
      left: 50%;
      margin-left: -7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.15);
    }
    &:after {
      position: absolute;
      content: '';
      left: 50%;
      margin-left: -7px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      animation: spinner 0.8s linear infinite;
      -webkit-animation: spinner 0.8s linear infinite;
      border: 2px solid $Color__Marshmellow;
      border-color: $Color__Marshmellow transparent transparent;
      border-style: solid;
      border-width: 2px;
      box-shadow: 0 0 0 1px transparent;
    }
    @keyframes spinner {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }

  &.Text {
    color: $Color__LoanstreetGreen;
    &:not(:disabled) {
      &:hover {
        color: $Color__LoanstreetGreenDark;
      }
      &:active {
        color: $Color__LoanstreetGreenDark1;
      }
    }
    &:disabled {
      color: $Color__Concrete;
    }
  }
}
