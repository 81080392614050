@import '../../../main.scss';

.readOnlyText {
  border: none !important;
  padding: 0px !important;
  @include setFont;

  p.textLine {
    margin: 0 !important;
    padding: 0 !important;
    @include setOptionalTextStyles;
  }

  &:hover,
  :focus {
    border: none !important;
    box-shadow: none !important;
  }
  &.Label {
    display: inline-block;
    padding: 0px 5px !important;
    border-radius: 2px;
    font-size: $FontSize__TableHeading;
    color: $Color__Marshmellow;
    margin-right: 12px;
    height: 14px;
    line-height: 14px;
    &.assignee {
      background-color: $Color__BrandGoldDark1;
    }
    &.stage {
      background-color: $Color__Robin;
    }
  }
}
