@import './scss/piecewise.scss';
@import './scss/colors.scss';
@import './scss/mixins.scss';
@import './scss/variables.scss';
@import './scss/scss-react-transition.scss';
@import './scss/resets.scss';

body {
  font-family: $FontStyle;
  color: $Color__Steel;
  font-size: $FontSize__ParagraphLarge;
  font-weight: $FontWeight__Normal;
}
