@import "../../../main.scss";

.DisplayTable-TableRow {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-right: 0.7rem;
  text-align: left;
  transform-origin: top;
  overflow-y: hidden;
  justify-content: space-between;
  align-items: center;

  &:nth-child(odd) {
    background-color: $white;
  }
  &:nth-child(even) {
    background-color: $gray200;
  }
  &:hover,
  &:focus {
    color: $green100;
  }
}
