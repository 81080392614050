@import '../../../main.scss';
@import '~react-dates/lib/css/_datepicker.css';

$RangePicker__Width: 490px;
$SingleDatePicker__Width: 240px;

$Day__Width: 30px;
$Day__Height: 30px;
%SpanOffset {
  &__Left {
    position: absolute;
    display: flex;
    content: '';
    width: $Day__Width;
    height: $Day__Height;
    background-color: $Color__RobinLight;
    margin: -6px -15px;
    z-index: -1 !important;
  }
  &__Right {
    position: absolute;
    display: flex;
    content: '';
    width: $Day__Width;
    height: $Day__Height;
    background-color: $Color__RobinLight;
    margin: -6px 15px;
    z-index: -1 !important;
  }
  &__Both {
    position: absolute;
    display: flex;
    content: '';
    width: $Day__Width * 2;
    height: $Day__Height;
    background-color: $Color__RobinLight;
    margin: -6px -15px;
    z-index: -1 !important;
  }
}
%BorderWithShadow {
  border-radius: 2px !important;
  box-shadow: $FormField__BoxShadow !important;
  background-color: $Color__Marshmellow !important;
  &--Unfocused {
    @extend %BorderWithShadow;
    border: solid 1px $Color__RobinLight1 !important;
  }

  &--Focused {
    @extend %BorderWithShadow;
    border: solid 1px $Color__RobinDark !important;
  }
}
.DateRangePicker {
  font-family: $FontStyle !important;

  &__withBorder {
    border: 0px !important;
  }
  &Input {
    background: transparent;
    border: 0px;
    left: 250px !important;
    padding: 0px !important;

    &__withBorder {
      border: 0px !important;
    }
    &_calendarIcon {
      visibility: visible;
      color: red !important;
    }
    &_arrow {
      display: none !important;
    }
  }
  &__MonthYearSelector {
    font-size: $FontSize__ParagraphLarge;
  }
}
.DateInput {
  display: none !important;
  &_input__focused {
    border: 0px !important;
  }
}
.DayPicker {
  background-color: rgba(0, 0, 0, 0) !important;
  padding: 0px !important;
  display: inline-block;
  &__withBorder {
    border: 0px !important;
    box-shadow: none !important;
  }
  &_weekHeader {
    padding: 0px !important;
    z-index: 0 !important;
    top: 54px !important;
    &:nth-child(2) {
      left: 250px !important;
    }
    &_ul {
      border-top: $Color__RobinLight2 1px solid;
      padding-top: 8px;
    }
    &_li {
      width: 32px !important;
      & > small {
        font-size: $FontSize__TableHeading;
      }
    }
  }
  &Navigation {
    &_button {
      &__default {
        border: 0px !important;
      }
      &__horizontalDefault {
        position: absolute;
        top: 20px;
        padding: 0px !important;
      }
    }
    &_leftButton__horizontalDefault {
      left: 21px;
    }
    &_rightButton__horizontalDefault {
      right: 21px;
    }
    &_svg__horizontal {
      height: 10px !important;
      margin-top: 2px;
      width: 6px;
      fill: $Color__Ink;
      display: block;
    }
  }
  &_transitionContainer {
    transition: height 0.4s ease-in-out !important;
    overflow: hidden !important;
  }
}
.SingleDatePicker .DayPicker {
  width: $SingleDatePicker__Width !important;
  &_transitionContainer,
  & > * > * {
    width: $SingleDatePicker__Width - 3px !important;
  }
}

.TwoDateRangePicker .DayPicker {
  width: $RangePicker__Width !important;
  &_transitionContainer,
  & > * > * {
    width: $RangePicker__Width - 3px !important;
  }
}

.CalendarMonth {
  padding: 0px !important;
  padding-right: 25px !important;
  background: transparent !important;
  &Grid {
    background: transparent !important;
  }
  &_caption {
    color: $Color__Ink !important;
    font-size: 13px !important;
    padding-top: 16px !important;
    padding-bottom: 48px !important;
  }
  &_table {
    border-collapse: separate !important;
    border-spacing: 0px 10px !important;
  }
}
.CalendarDay {
  width: $Day__Width + 2px !important;
  height: $Day__Height !important;
  font-size: $FontSize__ParagraphSmall;
  &__default {
    border: 0px !important;

    &:hover {
      align-items: center;
      justify-content: center;
      background-color: $Color__Marshmellow !important;
      border: solid 1px $Color__Robin !important;
      color: $Color__Ink !important;
      border-radius: 100% !important;
    }
    &:active {
      border-radius: 100%;
      width: $Day__Width + 2px !important;
      height: $Day__Height !important;
      background-color: $Color__Robin !important;
      border: 1px solid $Color__Robin;
      color: $Color__Marshmellow !important;
      z-index: 1 !important;
    }
  }
  &__lastDayOfWeek:not(:hover),
  &__firstDayOfWeek:not(:hover) {
    color: $Color__Steel !important;
  }
  &__hovered {
    &_span:not(:hover) {
      background: $Color__RobinLight !important;
      color: $Color__Ink !important;
      border: 0px;
      &::before:not(.CalendarDay__lastDayOfWeek) {
        @extend %SpanOffset__Right;
      }
      &::before:not(.CalendarDay__firstDayOfWeek) {
        @extend %SpanOffset__Left;
      }
      &::before:not(.CalendarDay__firstDayOfWeek):not(.CalendarDay__lastDayOfWeek) {
        @extend %SpanOffset__Both;
      }
      &.CalendarDay__lastDayOfWeek,
      &.CalendarDay__firstDayOfWeek {
        color: $Color__Steel !important;
      }
    }
    &_offset {
      background: $Color__Marshmellow !important;
    }
  }
  &__selected {
    &_span {
      background: $Color__RobinLight !important;
      color: $Color__Ink !important;
      border: 0px;
      &::before:not(.CalendarDay__lastDayOfWeek) {
        @extend %SpanOffset__Right;
      }
      &::before:not(.CalendarDay__firstDayOfWeek) {
        @extend %SpanOffset__Left;
      }
      &::before:not(.CalendarDay__firstDayOfWeek):not(.CalendarDay__lastDayOfWeek) {
        @extend %SpanOffset__Both;
      }
      & .CalendarDay__lastDayOfWeek,
      & .CalendarDay__firstDayOfWeek {
        color: $Color__Steel !important;
      }
    }
    &:not(:hover) {
      border-radius: 100%;
      width: $Day__Width + 2px !important;
      height: $Day__Height !important;
      background-color: $Color__Robin !important;
      border: 1px solid $Color__Robin;
      color: $Color__Marshmellow !important;
      z-index: 1 !important;
    }
    &:hover {
      color: $Color__Ink !important;
      background-color: $Color__Marshmellow !important;
    }
  }
}
.DatePicker {
  min-height: 250px !important;
  &__MonthYearSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -4px;
    &__CurrentYear.ui.dropdown {
      width: fit-content;
      border-color: transparent !important;

      left: -2px;
      top: -1px;
      & > .text {
        left: -3px;
        color: $Color__Ink !important;
      }
      &.lsDropdown.active.visible.selection {
        box-shadow: none !important;
        > input.search {
          border: none;
          color: transparent;
          text-shadow: 0 0 0 transparent;
          text-align: center;
          &:focus,
          &:hover,
          &:active {
            outline: none !important;
          }
        }
        > .visible.menu.transition {
          margin-top: -2px !important;
          z-index: 1000 !important;
        }
      }
    }
    &__Icon {
      color: $Color__Steel !important;
      font-size: $FontSize__TableHeading !important;
      margin-left: 5px !important;
    }
    &__YearDropdown {
      @extend %BorderWithShadow--Focused;
      position: absolute;
      z-index: 1;
    }
    &__Year {
      color: $Color__Steel;
      padding: 1.5px 8px 1.5px;
      margin: 0px 1px;
      &:first-child {
        padding-top: 5px;
      }
      &:last-child {
        padding-bottom: 5px;
      }
      &:hover {
        color: $Color__Ink;
        background-color: $Color__RobinLight;
        cursor: pointer;
      }
      &--Selected {
        color: $Color__Ink;
        background-color: $Color__RobinLight;
      }
    }
  }
}
