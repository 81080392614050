@import "../../main.scss";

.PopupMenu {
  border: 0 !important;
  box-shadow: 0 2px 4px 0 rgba($black, 0.3) !important;
  padding: 1px;
  color: $white;
  background-color: $black;
  &.visible {
    padding: 0 !important;
  }
  .menu {
    @include setFont;

    border-radius: 2px !important;
  }
  .PopupMenu-Menu {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 0.28571429rem !important;
    box-shadow: 0 2px 4px 0 rgba($black, 0.3) !important;
    a:hover,
    a:focus,
    label:hover,
    label:focus {
      background-color: $gray400 !important;
    }
  }

  .PopupMenu-DefaultTrigger--Disabled {
    @include noSelect();
    svg {
      margin: 0 1px;
      fill: $gray400;
    }
  }

  .PopupMenu-DefaultTrigger:not(.PopupMenu-DefaultTrigger--Disabled) {
    cursor: pointer;
    @include noSelect();
    @include clickAnimation();
    svg {
      margin: 0 1px;
      fill: $primaryGreen;
    }
  }

  &.ui.popup:before {
    box-shadow: 0 2px 4px 0 rgba($black, 0.3) !important;
    z-index: -1;
  }
}
