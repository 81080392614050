@import '../../../main.scss';

.FormSection {
  margin-bottom: 1rem;

  @include transition(
    'FormSection__Drawer',
    (
      opacity: (
        0,
        1,
      ),
    ),
    150
  );

  &__Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: 'no-wrap';
    &:hover:not(&--NoPointer),
    &:focus:not(&--NoPointer) {
      cursor: pointer;
    }
  }

  &__CollapseIcon {
    &.LSButton.IconButton {
      font-size: 18px;
      font-weight: bold;
      svg {
        margin-right: 6px;
      }
    }
  }
}
