.calendarDay {
  display: flex;
  flex-direction: column;
  text-align: left;
  background: $white;
  font-size: 14px;
  color: $black;

  &__Footer {
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    color: $primaryGreen;
  }
  &__ItemsContainer {
    height: 100%;
    overflow: hidden;

    .LSPopup__Trigger {
      width: 100%;
    }
  }

  &__CountContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
  }
  &--Secondary {
    color: $gray400 !important;
  }
  &--Today {
    border-top: solid 4px $primaryGreen;
  }
  &__Label {
    margin: 8px;
  }

  &__Badge {
    margin: 4px 8px 8px 8px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $white;
    background: $primaryGreen;
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);

    &--Compact {
      font-size: 14px;
      font-weight: bold;
      color: $primaryGreen;
      margin: 7px 8px;
    }
  }

  &__Item {
    cursor: pointer;
    display: inline-flex;
    width: -webkit-fill-available;
    width: -moz-available;
    justify-content: space-between;
    align-items: center;
    margin: 3px 2px;
    padding: 2px 5px 2px 5px;
    font-size: 10px;
    font-weight: bold;
    border-radius: 4px;
    background: $secondaryBlue;
    color: $white;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      color: $white;
      background: $blue700;
    }

    &--Holiday {
      cursor: initial;
      color: $white;
      background: $gray600;
      &:hover,
      &:focus {
        background: $gray600;
      }
    }
    &--Count {
      text-align: center;
    }
  }
}
