@import '../../../main.scss';

.TabNavigationMenu {
  max-width: 100vw;
  display: flex;
  margin-bottom: 24px;
  overflow-x: auto;
  align-items: flex-end;
  @include noScrollBar;

  &--WithRoutes {
    margin-bottom: 0;
  }

  &__Item {
    &:focus {
      outline: none;
    }
    & .LSButton__Label {
      text-align: left;
      display: inline-block;
      max-width: max-content;
      color: $Color__Steel;
      margin-right: 12px;
      border-radius: 0;
      padding-bottom: 2px;
      border-bottom: 4px solid transparent;
      font-size: $FontSize__H3;
      font-weight: $FontWeight__Normal;
      text-transform: uppercase;
      border-bottom: 2px solid rgba(0, 0, 0, 0%);
      box-shadow: none !important;
      opacity: 1 !important;
      margin-right: 32px;

      /* stylelint-disable a11y/no-outline-none */
      &:focus {
        color: $Color__LoanstreetGreen !important;
      }
      &:hover {
        color: $Color__LoanstreetGreen !important;
        cursor: pointer;
      }
    }
    &--Active {
      & .LSButton__Label {
        color: $Color__LoanstreetGreen !important;
        border-bottom: 2px solid $Color__LoanstreetGreen;
      }
    }
  }
}
