@import '../../../main.scss';
.FormField {
  &__SmallText {
    cursor: default;
    display: flex;
    align-items: center;
    display: inline-block;
    font-family: $FontStyle;
    font-size: $FontSize__HelperText;
    &--Error {
      color: $Color__Rose;
    }
    &--Help {
      color: $Color__Steel;
    }
  }
  &--Inline {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 !important;

    > label,
    > div {
      width: 50% !important;
      padding: 0 0.5em !important;
    }

    .FormReadOnly__Control {
      padding: 0 1.5em !important;
    }

    > label {
      margin: 0 !important;
      text-align: right !important;
    }
  }
}
.field {
  display: flex !important;
  flex-direction: column;
  margin-bottom: 8px;
  label {
    white-space: nowrap;
  }
  &.FormField--Error {
    .lsDropdown,
    .lsInput,
    > .LSTextArea {
      border-color: $Color__Rose !important;
    }
  }
  &.disabled:not(.disabledReadable),
  &.disabled:not(.disabledReadable) > label {
    opacity: 1 !important;
    background: transparent;
    color: $Color__Concrete !important;
    & .FormField__SmallText > * {
      color: $Color__Concrete !important;
    }
  }
  &.disabled:not(.disabledReadable) {
    svg.lsInput__Icon {
      color: $Color__Concrete !important;
    }
  }
  &.disabledReadable,
  &.disabledReadable > .ui.input,
  &.disabledReadable > * {
    > .lsInput--Disabled > input,
    .lsDropdown.disabled.ui > .text,
    > .LSTextArea,
    > .lsInput.disabled,
    > .lsInput.disabled > .display,
    .lsCalendar.disabled > .display,
    &.Radio > .Radio__Label,
    &.RadioGroup > .Radio__Label,
    &.Checkbox > .Checkbox__Label,
    &.CheckboxGroup > .Checkbox > .Checkbox__Label,
    &.CheckboxGroup > .Checkbox__Label {
      color: $Color__Ink !important;
    }
    > .lsInput.disabled > .placeholder,
    .lsCalendar.disabled > .display.placeholder,
    > .lsInput--Disabled > input::placeholder,
    .lsDropdown.disabled.ui.placeholder > .text,
    > .LSTextArea::placeholder {
      color: $Color__RobinLight1 !important;
    }
    > :not(.Radio__Container--Checked) > .Radio__Label,
    > :not(.Checkbox__Container--Checked) > .Checkbox__Label,
    .CheckboxGroup > :not(.Checkbox__Container--Checked) > .Checkbox__Label {
      color: $Color__Steel !important;
    }
  }
}
