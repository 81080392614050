/** Design's Color System. Please add as needed:
https://app.zeplin.io/project/5e2f34632b5bdc7f2037f1af/screen/5e2f53f710fbc095897bdb5f
*/
$Color__LoanstreetGreen: #419572;
$Color__LoanstreetGreenDark: #327d5e;
$Color__LoanstreetGreenDark1: #26664b;
$Color__LoanstreetGreenLight: #ecf9f7;
$Color__BrandGold: #eeab1f;
$Color__Rose: #cc0001;
$Color__RoseLight3: #ff0000;
$Color__Violet: #4a90e2;
$Color__Ivy: #419572;
$Color__Sunflower: #ffe802;
$Color__BrandGoldDark1: #c08100;
$Color__Robin: #677f94;
$Color__RobinDark: #5d7285;
$Color__RobinLight: #ebf2f8;
$Color__RobinLight1: #a4b2bf;
$Color__RobinLight2: #8599a9;
$Color__Steel: #747574;
$Color__Wall: #d2d2d2;
$Color__Ink: #1b252e;
$Color__Charcoal: #343434;
$Color__Slate: #585858;
$Color__Concrete: #d8d8d8;
$Color__Cloud: #eaeaea;
$Color__Tin: #788188;
$Color__Marshmellow: #ffffff;
$Color__Fog: #f9f9f9;

/** Deprecated color system below...do not use */

$white: #ffffff;
$black: #000000;

/* Deprecated colors below. Do not use!! */
$white: #ffffff;
$black: #000000;

/* Gray */
$gray900: #1f1f1f;
$gray800: #404040;
$gray700: #5f5f5f;
$gray600: #727272;
$gray500: #9b9b9b;
$gray400: #bbbbbb;
$gray300: #dedede;
$gray200: #ececec;
$gray100: #f4f4f4;

/* Gray Blue */
$grayBlue900: #000000;
$grayBlue800: #2b2b2b;
$grayBlue700: #4e4e4e;
$grayBlue600: #646464;
$grayBlue500: #767676;
$grayBlue400: #8c8c8c;
$grayBlue300: #a9a9a9;
$grayBlue200: #c3c3c3;
$grayBlue100: #d2d2d2;
$grayBlue50: #e4e4e4;

/* Gray Green */
$grayGreen: #2a332d;
$grayGreen900: #232b26;
$grayGreen700: #3b433d;
$grayGreen600: #646d67;
$grayGreen500: #78817b;
$grayGreen400: #a1aba5;
$grayGreen300: #bfc9c3;
$grayGreen200: #e1ece5;
$grayGreen100: #ecf4ef;

/* Green */
$primaryGreen900: #09432a;
$primaryGreen800: #125439;
$primaryGreen700: #26664b;
$primaryGreen600: #327d5e;
$primaryGreen500: #419572;
$primaryGreen400: #27d1a5;
$primaryGreen300: #7ae1bd;
$primaryGreen200: #cef9e7;
$primaryGreen100: #cef9e7;
$primaryGreen50: #ecfff7;
$primaryGreen: #419572;
$green900: #266749;
$green800: #2e684c;
$green700: #398465;
$green600: #3c8867;
$green400: #4fa584;
$green300: #67b597;
$green200: #8ecab4;
$green100: #b9dfd1;
$green50: #ecf4f0;
$impactGreen: #1cb85d;

/* Light Green */
$lightGreen900: #487559;
$lightGreen800: #629475;
$lightGreen700: #80ad91;
$lightGreen600: #96c3a7;
$lightGreen500: #b1d3be;
$lightGreen400: #c4e2cf;
$lightGreen300: #d5e9dc;
$lightGreen200: #e1eee6;
$lightGreen100: #ecf4ef;
$lightGreen50: #f6fbf8;

/* Blue */
$secondaryBlue: #4a90e2;
$blue900: #2f5397;
$blue800: #2568ca;
$blue700: #3577d8;
$blue500: #509ef0;
$blue400: #61acf3;
$blue300: #79bbf5;
$blue200: #9ccff9;
$blue100: #bad1ea;
$blue50: #e5f3fd;

/* Dark Blue */
$darkBlue900: #1b1b1b;
$darkBlue800: #202327;
$darkBlue700: #242a31;
$darkBlue600: #2f3944;
$darkBlue500: #3f5062;
$darkBlue400: #4e6681;
$darkBlue300: #6485aa;
$darkBlue200: #7ea5d0;
$darkBlue100: #98c1ee;
$darkBlue50: #badbff;

/* Yellow */
$primaryYellow900: #785000;
$primaryYellow800: #986600;
$primaryYellow700: #c08100;
$primaryYellow600: #d8950b;
$primaryYellow500: #eeab1f;
$primaryYellow400: #f5c342;
$primaryYellow300: #f6d577;
$primaryYellow200: #fde6a3;
$primaryYellow100: #fff1c8;
$primaryYellow50: #fff8e3;
$secondaryYellow: #eeab1f;

/* Red */
$primaryRed900: #670000;
$primaryRed800: #930000;
$primaryRed700: #af0000;
$primaryRed600: #cc0000;
$primaryRed500: #ff0000;
$primaryRed400: #ff5b5e;
$primaryRed300: #faa4a6;
$primaryRed200: #fec6c9;
$primaryRed100: #fddee0;
$primaryRed50: #fff0f1;
$red: #cc0000;
$red800: #930000;
$red700: #af0000;

/* Yellow */
$secondaryYellow: #eeab1f;

/* Brands  */
$powerpointRed: #b04b36;
$excelGreen: #457248;
$wordBlue: #3b5492;
$pdfRed: #ff0000;

/* Mixed */
$darkBlueGray: #29324a;
$blueGrayShadow: #9eb1bc;

/* stylelint-disable */
:export {
  white: $white;
  black: $black;

  gray900: $gray900;
  gray800: $gray800;
  gray700: $gray700;
  gray600: $gray600;
  gray500: $gray500;
  gray400: $gray400;
  gray300: $gray300;
  gray200: $gray200;
  gray100: $gray100;

  grayBlue900: $grayBlue900;
  grayBlue800: $grayBlue800;
  grayBlue700: $grayBlue700;
  grayBlue600: $grayBlue600;
  grayBlue500: $grayBlue500;
  grayBlue400: $grayBlue400;
  grayBlue300: $grayBlue300;
  grayBlue200: $grayBlue200;
  grayBlue100: $grayBlue100;
  grayBlue50: $grayBlue50;

  grayGreen: $grayGreen;
  grayGreen900: $grayGreen900;
  grayGreen700: $grayGreen700;
  grayGreen600: $grayGreen600;
  grayGreen500: $grayGreen500;
  grayGreen400: $grayGreen400;
  grayGreen300: $grayGreen300;
  grayGreen200: $grayGreen200;
  grayGreen100: $grayGreen100;

  primaryGreen900: $primaryGreen900;
  primaryGreen800: $primaryGreen800;
  primaryGreen700: $primaryGreen700;
  primaryGreen600: $primaryGreen600;
  primaryGreen500: $primaryGreen500;
  primaryGreen400: $primaryGreen400;
  primaryGreen300: $primaryGreen300;
  primaryGreen200: $primaryGreen200;
  primaryGreen100: $primaryGreen100;
  primaryGreen50: $primaryGreen50;
  primaryGreen: $primaryGreen;
  green900: $green900;
  green800: $green800;
  green700: $green700;
  green600: $green600;
  green400: $green400;
  green300: $green300;
  green200: $green200;
  green100: $green100;
  green50: $green50;
  impactGreen: $impactGreen;

  lightGreen900: lightGreen900;
  lightGreen800: lightGreen800;
  lightGreen700: lightGreen700;
  lightGreen600: lightGreen600;
  lightGreen500: lightGreen500;
  lightGreen400: lightGreen400;
  lightGreen300: lightGreen300;
  lightGreen200: lightGreen200;
  lightGreen100: lightGreen100;
  lightGreen50: lightGreen50;

  secondaryBlue: $secondaryBlue;
  blue900: $blue900;
  blue800: $blue800;
  blue700: $blue700;
  blue500: $blue500;
  blue400: $blue400;
  blue300: $blue300;
  blue200: $blue200;
  blue100: $blue100;
  blue50: $blue50;

  darkBlue900: $darkBlue900;
  darkBlue800: $darkBlue800;
  darkBlue700: $darkBlue700;
  darkBlue600: $darkBlue600;
  darkBlue500: $darkBlue500;
  darkBlue400: $darkBlue400;
  darkBlue300: $darkBlue300;
  darkBlue200: $darkBlue200;
  darkBlue100: $darkBlue100;
  darkBlue50: $darkBlue50;

  primaryYellow900: $primaryYellow900;
  primaryYellow800: $primaryYellow800;
  primaryYellow700: $primaryYellow700;
  primaryYellow600: $primaryYellow600;
  primaryYellow500: $primaryYellow500;
  primaryYellow400: $primaryYellow400;
  primaryYellow300: $primaryYellow300;
  primaryYellow200: $primaryYellow200;
  primaryYellow100: $primaryYellow100;
  primaryYellow50: $primaryYellow50;
  secondaryYellow: $secondaryYellow;

  primaryRed900: $primaryRed900;
  primaryRed800: $primaryRed800;
  primaryRed700: $primaryRed700;
  primaryRed600: $primaryRed600;
  primaryRed500: $primaryRed500;
  primaryRed400: $primaryRed400;
  primaryRed300: $primaryRed300;
  primaryRed200: $primaryRed200;
  primaryRed100: $primaryRed100;
  primaryRed50: $primaryRed50;
  red: $red;
  red800: $red800;
  red700: $red700;

  secondaryYellow: $secondaryYellow;

  powerpointRed: $powerpointRed;
  excelGreen: $excelGreen;
  wordBlue: $wordBlue;
  pdfRed: $pdfRed;

  darkBlueGray: $darkBlueGray;
  blueGrayShadow: $blueGrayShadow;
}
