@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,600');

$FormField__BoxShadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
$FormField__Height: 26px;

/** Design's Typography System. Please add as needed:
https://app.zeplin.io/project/5e2f34632b5bdc7f2037f1af/screen/5e2f5a8111211a5362ca3dcf
*/
$FontStyle: 'IBM Plex Sans', serif;

$FontSize__H3: 14px;
$FontSize__ParagraphLarge: 13px;
$FontSize__ParagraphSmall: 12px;
$FontSize__TableHeading: 11px;
$FontSize__HelperText: 9px;

$FontWeight__Normal: 400;
$FontWeight__Bold: 600;

$TileWidth__Full: 1168px;
$TileWidth__ThreeFourths: 872px;
$TileWidth__TwoThirds: 774px;
$TileWidth__Half: 576px;
$TileWidth__OneThird: 380px;
$TileWidth__OneFourth: 280px;

$Breakpoint__Laptop: 1256px;
$Breakpoint__TabletLandscape: 1024px;
$Breakpoint__TabletPortrait: 768px;
$Breakpoint__iPhone8: 375px;

$displayTableCellPadding: 3px 5px 1px 13px;
