.CalendarPopupContent {
  color: $Color__Ink;
  &__Header {
    margin-bottom: 12px;

    button {
      float: right;
    }
  }

  &__FieldWithIcon {
    display: flex;
    svg {
      margin-left: 12px;
    }
  }

  &__StatusIcon {
    font-size: 11px;
    text-align: right;
  }

  .SummaryField {
    margin-bottom: 8px;
  }
}
