@import '../../../main.scss';
.LSTextLink {
  box-sizing: content-box;
  min-height: max-content;
  outline: none;
  color: $Color__LoanstreetGreen;
  font-family: $FontStyle;
  outline: none;
  display: block;
  &:hover {
    cursor: pointer;
    color: $Color__LoanstreetGreenDark;
    outline: none;
  }
  &:active {
    color: $Color__LoanstreetGreenDark1;
    outline: none;
  }

  &.External {
    font-size: $FontSize__ParagraphLarge;
    font-weight: $FontWeight__Normal;
    min-width: 48px;
    width: max-content;
    justify-content: space-between;
    align-items: center;

    & .LSTextLink {
      &__Label {
        box-sizing: content-box;
        padding: 4px 2px 4px 12px;
      }
      &__Icon {
        font-size: 12px;
        padding: 3px 7px 0px 3px;
      }
    }
    & > .LSTextLink__Icon {
      color: $Color__Charcoal;
    }
  }

  &.Internal {
    & > .LSTextLink__Label {
      color: $Color__LoanstreetGreen;
      &:hover {
        cursor: pointer;
        color: $Color__LoanstreetGreenDark;
        outline: none;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: $Color__LoanstreetGreenDark;
      }
      &:active {
        cursor: pointer;
        color: $Color__LoanstreetGreenDark1;
        outline: none;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: $Color__LoanstreetGreenDark1;
      }
    }
  }
  &.inline {
    display: contents;
    > .LSTextLink__Label {
      margin: 0px;
      padding: 0px;
    }
  }
  &.bold > .LSTextLink__Label {
    font-weight: $FontWeight__Bold;
  }
}
