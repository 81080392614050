@import '../../../main.scss';

.formFrame {
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;

  @include setFont;
  .formContent {
    overflow-y: auto;
    padding: 16px 16px 14px;
    flex: 1 0 0%;
  }
  .formFooter {
    padding: 1.5rem 1.5rem 1rem;
    background-color: $green50;
    display: flex;
    align-items: center;
    button {
      margin-bottom: 0.75rem;
      margin-right: 0.5rem;
    }
    .readOnlyText {
      margin-bottom: 0.75rem;
      margin-left: 24px;
      > .footerMessage {
        font-size: $FontSize__ParagraphLarge;
        width: 300px;
      }
    }
  }
}
