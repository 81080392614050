@import "../../main.scss";

.checkGroup {
  display: flex;
  &__Option {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 19px;
    width: 19px;
    border-radius: 25px;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0px;
    }
    &--Checked {
      background: $primaryGreen;
      color: $white;
    }
    &--Unchecked {
      background: $gray400;
    }
  }
}
