@import "../../../main.scss";

.FormErrorList {
  padding: 4px 0px 4px 0px;

  .FormErrorList__LineItem {
    &--Hidden {
      visibility: hidden;
    }

    display: flex;
    align-items: center;
    color: $red;

    .FormErrorList__LineItem__Icon {
      margin-right: 10px;
    }
  }
}
