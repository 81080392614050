@import '../../main.scss';

@include transition(
  '.Accordion-Content',
  (
    max-height: (
      0,
      100vh,
    ),
    opacity: (
      0.7,
      1,
    ),
    transform: (
      translateY(-5px),
      initial,
    ),
  )
);
.Accordion {
  width: inherit;
  display: flex;
  flex-direction: column;
  .Accordion-Title {
    display: inline-flex;
    align-items: center;
    padding: 13px 25px !important;
    background-color: $green50;
    cursor: pointer;
    .Accordion-Header {
      @include noSelect();
      @include setFont;
    }
  }
  .Accordion-Content {
    padding: 0;
    overflow-y: hidden;
    &:not(.active) {
      max-height: 0;
    }
  }
  &__PlusMinus {
    color: $primaryGreen;
    font-size: 17px;
    margin-right: 4px;
  }
}
