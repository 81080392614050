@import "../../../main.scss";

.TimeInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    @include setCommonInputStyles;
    height: 30px;
    padding: 0 !important;
    text-align: center;
    width: 30% !important;
    &.TimeInput__Hour--invalid,
    &.TimeInput__Minute--invalid {
      border-color: $red !important;
      &:focus {
        outline: none;
        border: 1px solid $red;
        box-shadow: 0 0 4px $red;
      }
    }
    &.TimeInput--slim {
      height: 24px;
    }
  }
  &__Colon {
    margin: 0;
  }

  &__Colon-hidden {
    visibility: hidden;
  }
}
