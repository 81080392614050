@import "../../../../../main.scss";

.DatePickerPopup-Calendar-Month {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &--SixWeeks {
    height: 224px;
  }
  &--FiveWeeks {
    height: 194px;
  }
  &--FourWeeks {
    height: 164px;
  }

  &-DaysOfTheWeek {
    width: 100%;
    display: flex;
    flex-grow: 0;

    .DatePickerPopup-Calendar-Month-DayOfTheWeek {
      flex: 1;
      overflow: hidden;
      width: 38px;
      height: 32px;
    }

    .DatePickerPopup-Calendar-Month-Days {
      max-width: 296px;
    }
  }
}
