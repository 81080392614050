@import '../../main.scss';

.Notification {
  width: 348px;
  height: 70px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid $Color__RobinLight1;
  &:last-child {
    border-bottom: 1px solid $Color__RobinLight1;
  }
  &:not(.isRead) {
    background-color: $Color__LoanstreetGreenLight;
  }
  &:hover {
    background-color: $Color__RobinLight;
    cursor: pointer;
  }
  > .Column {
    margin: 8px 4px 8px 4px;

    &.IconContainer {
      margin-left: 8px;
      min-width: 36px;
      height: 36px;
      color: $Color__LoanstreetGreen;
      border: 1px solid $Color__LoanstreetGreen;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .fa-file-signature {
        margin-left: 4px;
      }
    }
    &.MainContent {
      overflow: hidden;
      color: $Color__Steel;
      > .Stack {
        margin-bottom: 4px;
        &.Body {
          font-size: $FontSize__ParagraphLarge;
          @include textOverflow(2);
        }
        &.MetaData {
          display: flex;
          flex-direction: row;
          font-size: $FontSize__ParagraphSmall;
          line-height: $FontSize__ParagraphSmall;
          .Item {
            white-space: nowrap;
            &:not(:first-child) {
              &:before {
                content: '\00a0 | ';
              }
              &:not(:last-child) {
                @include textOverflow();
              }
            }
          }
        }
      }
    }
  }
}
