@import '../../main.scss';

.Alert {
  width: 800px;
  max-width: 80vw;
  min-height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-left: 8px solid;
  &--success {
    background-color: $grayGreen100;
    border-color: $primaryGreen;
  }
  &--info {
    background-color: $blue50;
    border-color: $secondaryBlue;
  }
  &--warning, &--debug {
    background-color: $primaryYellow50;
    border-color: $primaryYellow500;
  }
  &--error {
    background-color: $primaryRed50;
    border-color: $red;
  }
  .Message {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 16px;
    .Text {
      padding: 10px 0;
      text-align: left;
      &__Header {
        font-weight: bold;
        padding-right: .5em;
      }
      &__Link {
        color: $green700;
        font-weight: bold;
        text-decoration: underline;
        display: inline;
        padding-left: .25em;
      }
    }
  }
  &__Icon {
    flex-grow: 0;
    display: flex;
    align-items: center;
    &--Signifier {
      font-size: 11px;
      padding: 0 15px 0 12px;
    }
    &--Exit {
      padding: 0 23px 0 7px;
      .IconButton {
        font-size: 19px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

.ToastAlert {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  transition: transform .5s ease;
  margin-bottom: 16px;
}
