@import '../../../main.scss';

.Toggle {
  $toggle: &;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__Slider {
    display: inline-block !important;
    position: absolute;
    width: 32px;
    height: 16px;
    border-radius: 8px;
    background-color: $Color__Wall;
    transition: 0.2s;

    &:before {
      position: absolute;
      content: '';
      border-radius: 50%;
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: $Color__Fog;
      transition: 0.2s;
    }
  }

  &__Input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + #{$toggle}__Slider {
      background-color: $Color__Robin;
    }

    &:checked + #{$toggle}__Slider:before {
      transform: translateX(16px);
    }
  }
  &__Slider:hover:before {
    box-shadow: 0 2px 6px 0 $Color__Slate;
  }

  &__Label {
    display: inline-block !important;
    font-style: $FontStyle;
    color: $Color__Ink;
    padding-left: 38px;
    font-size: $FontSize__ParagraphLarge;
  }
  &--Unchecked > .Toggle__Label {
    color: $Color__Steel;
  }
}
