@import '../../../main.scss';

.maskedInput {
  @include setCommonInputStyles;
  padding: 0px !important;

  &--WithSuffix,
  &--WithSuffix--Disabled {
    width: 100%;
    padding: 0px !important;

    @include setInputHeightAndCenterVertically(30px);
    input {
      @include setInputHeightAndCenterVertically(30px);
    }

    border: none !important;
    background-color: transparent !important;
  }

  &--WithSuffix {
    &:focus-within {
      outline: 0 !important;
    }

    &__Input {
      border: none !important;
      background-color: transparent !important;

      &:focus {
        outline: 0 !important;
      }
      &:hover,
      &:focus {
        box-shadow: none !important;
      }
    }
    &__Label {
      border: none !important;
      padding-right: 0.65rem;
    }
  }

  &--WithSuffix--Disabled {
    @include setFormDisabledStyles;

    &__Input {
      @include setFormDisabledStyles;
    }
  }

  &::placeholder {
    color: $Color__RobinLight1 !important;
  }
}
