@import '../../main.scss';

.Tiler {
  display: flex;
  flex-direction: row;
  &.wrap {
    flex-wrap: wrap;
  }
  align-self: flex-start;
  overflow-x: auto;
  padding: 16px;
  height: 100%;
  @include hideScrollbars;
  .Tile {
    background-color: $Color__Marshmellow;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 16px 24px 24px;
    height: fit-content;
    margin: 0 16px 16px 0;
    max-width: 100%;
    background-color: $Color__Marshmellow;
    min-height: 280px;
    @include setOptionalTextStyles;
    .Tile__Header {
      margin-bottom: 16px;
      margin-right: 16px;
      flex-basis: auto;
      position: relative;
    }
    &::after {
      content: '';
      width: 16px;
      height: 1px;
      position: absolute;
      left: 100%;
      top: 0px;
    }
    &.Full {
      @include setFixedWidth($TileWidth__Full);
    }
    &.ThreeFourths {
      @include setFixedWidth($TileWidth__ThreeFourths);
    }
    &.TwoThirds {
      @include setFixedWidth($TileWidth__TwoThirds);
    }
    &.Half {
      @include setFixedWidth($TileWidth__Half);
    }
    &.OneThird {
      @include setFixedWidth($TileWidth__OneThird);
    }
    &.OneFourth {
      @include setFixedWidth($TileWidth__OneFourth);
    }
    &.shrinkThreeFourths {
      @media (max-width: $Breakpoint__TabletLandscape) {
        &.Full {
          @include setFixedWidth($TileWidth__ThreeFourths);
        }
      }
    }
    &.shrinkHalf {
      @media (max-width: $Breakpoint__TabletPortrait) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds {
          @include setFixedWidth($TileWidth__Half);
        }
      }
    }
    &.shrinkOneThird {
      @media (max-width: $Breakpoint__iPhone8) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds,
        &.Half {
          @include setFixedWidth($TileWidth__OneThird);
        }
      }
    }
    &.shrinkOneFourth {
      @media (max-width: $Breakpoint__iPhone8) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds,
        &.Half,
        &.OneThird {
          @include setFixedWidth($TileWidth__OneFourth);
        }
      }
    }
    &__Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      .lsHeader {
        font-weight: $FontWeight__Normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .SecondarySection {
        display: flex;
        justify-content: flex-end;
        margin-right: -4px;
        margin-top: -4px;
        > *:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    &__Body {
      overflow: scroll;
      @include noScrollBar;
    }
    &__Footer {
      margin: 24px -8px -8px 0px;
      display: flex;
      justify-content: flex-end;
      .CtaButtons {
        display: flex;
        > *:not(:last-child) {
          margin-right: 4px;
        }
      }
      &.left {
        justify-content: flex-start;
        .CtaButtons {
          flex-flow: row-reverse;
        }
      }
    }
  }
  &__Column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: -16px;
    margin-right: 16px;
  }
  &__Row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -16px;
  }
}
