@import '../../main.scss';

.LSDivider {
  margin: 24px 0 4px !important;
  &:not(.vertical) {
    border-bottom: 1px solid $Color__RobinLight1;
    border-top: 0px;
    &.steel {
      border-bottom: 1px solid $Color__Steel;
    }
    &.ink {
      border-bottom: 1px solid $Color__Ink;
    }
    &.transparent {
      border-bottom: 1px solid transparent;
    }
  }

  &--Thin {
    margin: 0 0 4px 0 !important;
  }
  &--Vertical {
    color: $Color__RobinLight1;
    &.transparent {
      color: transparent;
    }
    font-size: 18px;
    @include noSelect();
    opacity: 0.7;
    margin: 0 8px !important;
    border: 0px;
    @include setOptionalTextStyles;
  }
}
