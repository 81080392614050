@import '../../../main.scss';
.lsCalendar {
  &:hover {
    cursor: pointer;
  }
  > .display {
    color: $Color__Ink;
    &.placeholder {
      color: $Color__RobinLight1;
    }
  }
  &.disabled {
    border: 1px solid $Color__Concrete !important;
    cursor: auto;
    > .display {
      color: $Color__Concrete;
    }
  }
}
