@import '../../../../main.scss';

.DatePickerPopup {
  border: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  margin: 24px 10px 0 0 !important;

  .DatePickerPopup-Body {
    text-align: center;

    &.DatePickerPopup--PickingDay,
    &.DatePickerPopup--PickingWeek {
      .middle {
        > div {
          flex-basis: 50%;
          width: 50%;
        }
      }
    }
    &.DatePickerPopup--PickingMonth,
    &.DatePickerPopup--Picking3month {
      width: 296px;
    }

    .DatePickerPopup-Content {
      background-color: $white;
      padding: 11px 7px 11px 11px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 0 8px 0 $gray500;

      .DatePickerNavigationButton {
        &--Left,
        &--Right {
          background-color: $primaryGreen !important;
          width: 21px;
          height: 21px;
          border-radius: 100%;
          font-size: 14px;
        }
      }

      .DatePickerPopup-CalendarPicker {
        display: flex;
        align-items: center;
        padding: 6px 0 9px 0;
        text-align: center;
      }
      .DatePickerPopup-Bottom {
        text-align: center;
        color: $primaryGreen;
        font-weight: bold;
        cursor: pointer;
        @include noSelect();
      }
    }
  }
  &::before {
    box-shadow: 0 0 8px 0 $gray500 !important;
    z-index: -1 !important;
  }
  &::after {
    background-color: $white;
    position: absolute;
    content: '';
    width: 0.71428571em;
    height: 0.71428571em;
    transform: rotate(45deg);
    box-shadow: none;
    z-index: 1 !important;
    bottom: auto;
  }
  &.bottom.right::after,
  &.top.left::after {
    margin-left: 0;
  }
  &.bottom.right::after,
  &.left.center::after {
    left: auto;
  }
  &.bottom.right::after {
    top: -0.30714286em;
    right: 1em;
    margin-left: 0;
  }

  &.top.left::after,
  &.right.center::after {
    right: auto;
  }

  &.top.left::after {
    bottom: -0.30714286em;
    left: 1em;
    top: auto;
  }
  &.left.center::after,
  &.right.center::after {
    top: 50%;
    margin-top: -0.30714286em;
  }
  &.left.center::after {
    right: -0.30714286em;
  }
  &.right.center::after {
    left: -0.30714286em;
  }
}
