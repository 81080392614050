@import "../../main.scss";

@include transition(
  ".TinyCollapse-Content",
  (
    max-height: (
      0,
      100vh
    ),
    opacity: (
      0.7,
      1
    ),
    transform: (
      translateY(-5px),
      initial
    )
  )
);

.TinyCollapse {
  &-Arrow {
    padding: 0 6px;
    width: 24px;
  }
  .TinyCollapse-Title {
    padding: 0 !important;
    .TinyCollapse-Header {
      @include noSelect();
    }
  }
  .TinyCollapse-Content {
    padding: 9px 0 0 21px !important;
  }
}
