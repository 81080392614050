@import "../../main.scss";

.LoadingIndicator {
  margin: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .LoadingIndicator-Icon {
    font-size: 50px;
    color: $red800;
  }

  &--Absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--Dimmed {
    background-color: rgba($white, 0.75);
  }
}
