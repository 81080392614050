@import "../../main.scss";

.FiltersColumn {
  flex: 0 0 auto;
  max-width: 215px;
  padding: 6px 8px 10px;
  overflow-y: auto;
  background-color: $white;
  border-left: 1px solid $gray400;
  text-align: left;
  display: flex;
  flex-direction: column;
  .FiltersColumn-ColumnHeader {
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    flex-shrink: 0;
    .name {
      font-weight: bold;
      @include noSelect();
    }
    .reset {
      font-size: 12px;
      opacity: 0.83;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    .FiltersColumn-Search {
      &::-webkit-search-cancel-button {
        -webkit-appearance: searchfield-cancel-button;
      }
      #FiltersSearchInput {
        background-color: $white !important;
        border: 1px $gray600 solid;
        margin-top: 5px;
        &:hover {
          box-shadow: 0 0 4px 0 $gray300;
        }
        &:focus {
          border: 1px solid $primaryGreen;
        }
      }
    }
    .FiltersColumn-FiltersSections {
      margin-top: 8px !important;
      // max-height: 400px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      .FiltersColumn-FiltersSection {
        @include transition(
          "filtersPane",
          (
            max-height: (
              0,
              500px
            )
          )
        );
        .filtersPane {
          background-color: $white;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          padding: 11px;
          // .field {

          .checkbox {
            text-align: left;
            margin: 7px 0px 7px 0px;
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }

            label {
              color: $black !important;
              &:before {
                background-color: $white !important;
                border-color: $gray400 !important;
                border-width: 2px;
              }
              &:after {
                font-size: 11px;
                background-color: $secondaryYellow;
                border-radius: 0.21428571rem;
                color: $black !important;
              }
            }

            @include checkBoxStyling;
          }
          // }
        }
      }
    }
  }
  .hideCompleted {
    margin: 16px 0 0 8px;
  }

  .filtersSectionHeader {
    display: flex;
    align-items: center;
    padding: 8px;
    width: 100%;
    background: transparent;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &__Icon {
      display: flex;
      flex: 0;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      > img {
        width: 18px;
      }
    }

    &__Content {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }
}
