@import "../../main.scss";

.DocumentCard {
  flex: 1 0 48%;
  max-width: calc(100% - 6px);
  margin: 0 3px 6px;
  display: flex;
  background-color: $white;
  padding: 8px 10px 10px;
  text-align: left;
  cursor: pointer;
  &:hover,
  &:focus {
    background: $green100;
  }
  .DocumentCard-Left {
    padding: 3px 5px 0 0;
    > img,
    > svg {
      font-size: 20px;
    }
    @include setFileIconColors;
  }
  /* stylelint-disable */
  .DocumentCard-Right {
    margin-left: 5px;
    max-width: calc(100% - 26px);
    a {
      font-size: 14px;
      text-decoration: none;
      color: $black;
      line-height: 1.2;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      hyphens: auto;
    }
    .DocumentCard-FolderLink {
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.5px;
      margin-top: 3px; //6px
      > span:last-child {
        color: $green700;
      }
    }
  }
}

@include transition(
  "DocumentCard",
  (
    opacity: (
      0.5,
      1,
      1,
      0
    ),
    transform: (
      scaleX(0.95),
      initial
    )
  ),
  200,
  ease-out
);
