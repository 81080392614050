@mixin clickAnimation($opacity: 0.6, $time: 50ms) {
  opacity: 1;
  transition: $time ease;
  &:hover,
  &:focus {
    opacity: $opacity;
  }
}

@mixin clickAndHoverAnimation($opacity: 0.5, $time: 50ms) {
  opacity: 1;
  transition: $time ease;
  &:hover,
  &:focus {
    transform: translateY(-1px);
    opacity: $opacity;
  }
  &:active,
  &.active {
    transform: translateY(2px);
  }
}

@mixin noSelect() {
  user-select: none;
}

@mixin noScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin antialiasing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin grabbing() {
  cursor: grabbing;
}

@mixin textOverflow($numLines: 1) {
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  -webkit-box-pack: end;
}

@mixin tableRowHover() {
  color: $black;
  svg {
    fill: $gray900;
  }
  /* stylelint-disable */
  transition: background-color 75ms ease;
  /* stylelint-enable */
  background-color: $green100;
}

@mixin contentCenterVertical {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

@mixin buttonStateColorStyling($hover, $active) {
  &:focus:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $active !important;
  }
  &:active:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $active !important;
  }
  &:hover:not(.disabled) {
    /* stylelint-disable */
    transition: background-color 0.3s ease;
    /* stylelint-enable */
    background: $hover !important;
  }
}

@mixin checkBoxStyling {
  label {
    font-weight: normal;
    &::before {
      border-color: $gray500 !important;
      border-width: 2px !important;
    }
    &:hover::before,
    &:focus,
    &:focus::before {
      border-color: $gray400 !important;
      border-width: 2px !important;
    }
    &:after {
      border-radius: 0.21428571rem;
    }
  }

  &:not(.disabled) {
    label {
      &:after {
        background: $primaryGreen !important;
        color: $white !important;
      }
    }
  }
}

@mixin setFont {
  font-family: $FontStyle !important;
}

@mixin setFormDisabledStyles {
  background: transparent !important;
  border: 1px solid $Color__Concrete !important;
  text-align: left;
  resize: none !important;
  color: $Color__Concrete !important;
  + .inputIcon svg {
    opacity: 1;
    color: $Color__Concrete;
  }
  ::placeholder {
    color: $Color__Concrete !important;
  }
  > i.dropdown.icon {
    display: none;
  }
}

@mixin setFileIconColors {
  &--FilePdf {
    color: $pdfRed;
  }
  &--FileImage {
    color: $secondaryBlue;
  }
  &--FileWord {
    color: $wordBlue;
  }
  &--FilePowerpoint {
    color: $powerpointRed;
  }
  &--FileExcel {
    color: $excelGreen;
  }
  &--FileCsv {
    color: $gray600;
  }
  &--Folder {
    color: $gray600;
  }
  &--Clock {
    color: $primaryGreen;
  }
}

@mixin setInputHeightAndCenterVertically($height) {
  display: flex;
  align-items: center;
  min-height: $height;
  height: $height !important;
}

@mixin setFixedWidth($width) {
  min-width: $width;
  max-width: $width;
}

@mixin setDropdownHeight($height) {
  .ui.search.dropdown {
    @include setInputHeightAndCenterVertically($height);

    > i.dropdown.icon,
    div.visible.menu.transition > div.message,
    input.search,
    .menu > .item {
      @include setInputHeightAndCenterVertically($height);
    }
    .menu {
      .item {
        @include setInputHeightAndCenterVertically($height);
      }
    }
  }
}

@mixin setRadioStyles {
  label {
    color: $darkBlueGray !important;
    margin-left: 2.1px;
    &::before {
      border-radius: 100%;
      border-color: $gray500 !important;
      border-width: 1.5px !important;
      background-color: $white !important;
      transform: scale(1.33) !important;
    }
    &:hover::before,
    &:focus::before {
      border-radius: 100%;
      border-color: $gray600 !important;
    }
    &::after {
      border-radius: 100%;
      border-color: $primaryGreen !important;
      background: $primaryGreen !important;
      color: $white !important;
      transform: scale(0.68) !important;
    }
  }

  &.checked label {
    &::before {
      border-radius: 100%;
      border-color: $primaryGreen !important;
    }
  }
}

@mixin setCommonInputStyles {
  background-color: $Color__Marshmellow !important;
  font-family: $FontStyle !important;
  font-size: $FontSize__ParagraphLarge !important;
  border-radius: 2px !important;
  border: 1px solid $Color__RobinLight1 !important;
  color: $Color__Ink !important;

  outline: 0;
  outline-color: initial;
  outline-style: initial;
  outline-width: 0px;

  &::placeholder {
    color: $Color__RobinLight1 !important;
  }

  @include setInputHeightAndCenterVertically($FormField__Height);
  input {
    @include setInputHeightAndCenterVertically($FormField__Height);
  }
  padding: 0px 8px !important;

  &:focus {
    border: 1px solid $Color__Ink !important;
  }
  &:disabled {
    @include setFormDisabledStyles;
  }
}

@mixin setOptionalTextStyles {
  &.required:after {
    content: ' *';
    color: $Color__Rose !important;
  }
  &.bold {
    font-weight: $FontWeight__Bold !important;
  }
  &.steel {
    color: $Color__Steel !important;
  }
  &.ink {
    color: $Color__Ink !important;
  }
  &.caps {
    text-transform: uppercase !important;
  }
  &.italic {
    font-style: italic !important;
  }
}

@mixin hideScrollbars {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
