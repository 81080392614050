@import "../../../../../main.scss";

.DatePickerPopup-Calendar-Week {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  &--PickingWeek {
    &:hover,
    &:focus {
      background-color: $blue700;
      cursor: pointer;
      > .DatePickerPopup-Calendar-Day.DatePickerPopup-Calendar-Day--Today {
        background-color: $blue700 !important;
        color: $black !important;
        &::before {
          content: "";
          background-color: $blue700;
          position: absolute;
          height: 4px;
          width: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &--PickingWeek.DatePickerPopup-Calendar-Week--Selected {
    background-color: $secondaryBlue;
    color: $white;
    font-weight: bold;
  }
}
