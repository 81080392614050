@import '../../main.scss';

.ui.form {
  & input:not([type]),
  & input[type='date'],
  & input[type='datetime-local'],
  & input[type='email'],
  & input[type='file'],
  & input[type='number'],
  & input[type='password'],
  & input[type='tel'],
  & input[type='text'],
  & input[type='time'],
  & input[type='url'],
  & textarea {
    @include setFont;
    border-radius: 2px !important;
    &:hover {
      box-shadow: none !important;
    }
  }
  & input:not([type]),
  & input[type='search'] {
    padding: 0px !important;
    background-color: transparent !important;
    border-width: 0px !important;
  }
  & .field > label {
    font-weight: $FontWeight__Normal !important;
  }
  & .fields .field,
  & .fields .field.wide {
    width: auto !important;
  }
}

.ui.form.lsForm {
  .field {
    padding: 0 1em 0 0 !important;
    margin-bottom: 12px;
    &.InputHeader {
      margin-bottom: 0;
    }
  }
  p {
    margin: 0;
    line-height: 1.57142857143em;
  }
  .required.field.disabled > label,
  .required.field > label.Radio {
    // Remove required indicator (red star) on disabled fields & Radio labels
    &::after {
      content: '';
    }
  }
  input,
  textarea {
    &::placeholder,
    &:focus::placeholder {
      color: $Color__RobinLight1;
    }
    .disabled:not(.disabledReadable) input::placeholder {
      color: $Color__Concrete;
    }
  }
}
.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field,
.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  opacity: 1 !important;
}
