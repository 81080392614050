@import '../../main.scss';

.lsHeader {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  @include setFont;

  &--Link {
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
// TODO: Refactor to use mixins
.ui.header,
.lsHeader {
  font-family: $FontStyle;
  font-weight: $FontWeight__Normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  @include setOptionalTextStyles;
}

h1.ui.header,
h1.lsHeader {
  font-size: 24px;
}
h2.ui.header,
h2.lsHeader {
  font-size: 20px;
  display: inline-block;
  text-align: left;
  display: inline-block;
}
h3.ui.header,
h3.lsHeader {
  font-size: 18px;
  text-align: left;
  display: inline-block;
}
h4.ui.header,
h4.lsHeader {
  font-size: 16px;
  text-align: left;
  display: inline-block;
  &--Link {
    //TODO: Determine if this is still needed
    &:hover,
    &:focus {
      color: lighten($darkBlueGray, 5);
    }
  }
}
h5.ui.header,
h5.lsHeader {
  font-size: 13px;
  text-align: left;
  display: inline-block;
}
