.calendarWeek {
  height: 100%;
  background: $white;
  padding: 0px 8px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  // Normally we can rely on autoprefixer to set the `-ms-` prefix, but here we
  // need to set a different value for IE. That's because `gird-template-rows:
  // auto` fixes this issue: https://loan-street.atlassian.net/browse/NX-235
  // `-ms-grid-rows: 100%;` doesn't cause that issue in IE, and `-ms-grid-rows:
  // auto;` prevents the calendarWeek from taking up 100% of the height of the
  // calendarHeader.
  -ms-grid-rows: 100%;
  &__Label {
    display: flex;
    white-space: nowrap;
    min-width: auto;
    margin-bottom: 3px;

    &--Today {
      color: $primaryGreen;
    }
    &--Secondary {
      color: $gray400;
    }
  }

  &__DateLabel {
    font-weight: bold;
    margin-left: 4px;
    height: 24px;

    &--Today {
      margin-left: 4px;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: $white;
      background: $primaryGreen;
      -webkit-clip-path: circle(50% at 50% 50%);
      clip-path: circle(50% at 50% 50%);
    }
  }

  &__Events {
    flex-grow: 1;
    border-top: solid 2px $gray400;
    border-right: solid 1px $gray400;
    overflow-y: auto;

    &--Today {
      border-top: solid 2px $primaryGreen;
    }

    .LSPopup__Trigger {
      width: 100%;
    }
  }

  &__EventItem {
    margin: 2px;
    border-radius: 4px;
    background: $secondaryBlue;
    color: $white;
    padding: 4px 5px 3px 5px;
    &:hover,
    &:focus {
      background: $blue700;
    }
    &--Clickable {
      cursor: pointer;
    }

    &--Holiday {
      background: $gray600;
      color: $white;
      &:hover,
      &:focus {
        background: $gray600;
      }
    }
  }
  &__EventName {
    font-weight: bold;
    a {
      color: $white;
    }
  }
  &__StatusIcon {
    text-align: right;
  }
  &__EventDescriptionGroup {
    margin-top: 8px;
  }
  &__WeekItem {
    color: $black;
    display: flex;
    flex-direction: column;

    &:last-child > .calendarWeek__Events {
      border-right: none !important;
    }
  }
}
