@import "../../../main.scss";
@import "./DisplayTableRow";
@import "./DisplayTableRowCell";
@import "./DisplayTableHeaderCell";
@import "./DisplayTableHeader";

.DisplayTable {
  width: 100%;
  height: 100%;
  border-spacing: 0;
  display: flex;
  flex-flow: column;
  background-color: $white;
  .DisplayTable-TableBody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    .DisplayTable-TableBody {
      &:nth-child(odd) {
        background-color: $white;
      }
      &:nth-child(even) {
        background-color: #f8f3ed;
      }
    }
    &.DisplayTable-Hoverable {
      tr {
        &:hover,
        &:focus {
          @include tableRowHover();
        }
      }
    }
  }
}
