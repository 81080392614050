@import '../../main.scss';
.SlideoutPanel {
  color: $Color__Ink;
  position: fixed;
  background-color: $Color__Marshmellow;
  width: 380px;
  height: 100%;
  box-shadow: 1px 12px 38px $Color__Wall;
  top: 0;
  &.leftSnap {
    left: 0;
  }
  &.rightSnap {
    right: 0;
  }
  &.visible.transition {
    display: flex !important;
  }
  flex-flow: column;
  > .Stack {
    margin: 16px 16px 0;
    &.Header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .CloseIcon {
        color: $Color__Ink;
      }
    }
    &.Content {
      overflow: auto;
      @include noScrollBar;
    }
  }
}
