@import '../../main.scss';

.ModalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  .Modal {
    &Overlay {
      width: 100%;
      height: 100%;
      background-color: $Color__Tin;
      opacity: 0.83;
    }
    position: absolute;
    background-color: $Color__Marshmellow;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    padding: 16px 24px 24px;
    top: 56px;
    max-height: calc(100vh - 112px);
    margin: auto;
    overflow: hidden;
    z-index: 1;
    @include setOptionalTextStyles;

    &.Full {
      @include setFixedWidth($TileWidth__Full);
    }
    &.ThreeFourths {
      @include setFixedWidth($TileWidth__ThreeFourths);
    }
    &.TwoThirds {
      @include setFixedWidth($TileWidth__TwoThirds);
    }
    &.Half {
      @include setFixedWidth($TileWidth__Half);
    }
    &.OneThird {
      @include setFixedWidth($TileWidth__OneThird);
    }
    &.OneFourth {
      @include setFixedWidth($TileWidth__OneFourth);
    }
    &.shrinkThreeFourths {
      @media (max-width: $Breakpoint__Laptop) {
        &.Full {
          @include setFixedWidth($TileWidth__ThreeFourths);
        }
      }
    }
    &.shrinkHalf {
      @media (max-width: $Breakpoint__TabletLandscape) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds {
          @include setFixedWidth($TileWidth__Half);
        }
      }
    }
    &.shrinkOneThird {
      @media (max-width: $Breakpoint__TabletPortrait) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds,
        &.Half {
          @include setFixedWidth($TileWidth__OneThird);
        }
      }
    }
    &.shrinkOneFourth {
      @media (max-width: $Breakpoint__iPhone8) {
        &.Full,
        &.ThreeFourths,
        &.TwoThirds,
        &.Half,
        &.OneThird {
          @include setFixedWidth($TileWidth__OneFourth);
        }
      }
    }
    font-style: $FontStyle;
    font-size: $FontSize__ParagraphLarge;
    &Header {
      display: flex;
      margin-bottom: 16px;
      justify-content: space-between;
      align-items: flex-start;
      & > .ModalHeader__Content {
        font-size: 18px;
      }
      & > .ui.button.IconButton.ModalHeader__CloseIcon {
        width: 10px;
        color: $Color__Charcoal;
      }
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &Body {
      height: 100%;
      margin-bottom: 16px;
      overflow-y: scroll;
      @include hideScrollbars;
    }
    &Footer {
      display: flex;
      justify-content: flex-end;
      margin-bottom: -8px;
    }
  }
}
