@import '../../main.scss';

.Filter {
  font-style: $FontStyle;
  position: relative;
  width: max-content;

  &.Label {
    color: $Color__Steel;
    background-color: $Color__Marshmellow;
    border: 1px solid $Color__Concrete;
    padding: 3px $FontSize__ParagraphSmall + 4px;
    border-radius: $FontSize__ParagraphSmall + 4px;
    font-size: $FontSize__ParagraphSmall;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.active {
      color: $Color__Marshmellow;
      border: 1px solid $Color__Robin;
      background-color: $Color__Robin;
    }
    &:hover {
      cursor: pointer;
      color: $Color__Steel;
      background-color: $Color__Concrete;
      border: 1px solid $Color__Concrete;
    }
    &.open,
    &.open:hover {
      color: $Color__Robin;
      border: 1px solid $Color__Robin;
      background-color: $Color__Marshmellow;
    }
  }
  &Popup {
    height: max-content !important;
    z-index: 1000;
    border-radius: 2px;
    box-shadow: $FormField__BoxShadow;
    background-color: $Color__Marshmellow;
    border: solid 1px $Color__RobinLight;
    border-radius: 6px;
    display: block;
    position: absolute;
    &.DatePicker {
      $Picker__Width: 490px;
      width: $Picker__Width;
      height: max-content;
      padding-bottom: 28px;
    }
    .Content {
      flex: 1 1 auto;
      .Checkbox__Label,
      .Radio__Label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .FilterFooter {
      position: absolute;
      vertical-align: bottom;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      left: 0;
      .TextButton {
        padding: 8px 8px !important;
        .Clear.hidden {
          visibility: hidden;
        }
      }
    }
    &.CheckboxGroup,
    &.Radio {
      padding: 12px 12px 48px 12px;
      width: 160px;
    }
  }
}
