@import '../../../main.scss';

.lsFormFieldLabel {
  cursor: pointer;
  display: block;
  color: $Color__Steel !important;
  font-size: $FontSize__TableHeading !important;
  padding-bottom: 3px !important;
  margin-bottom: 0 !important;
  @include setFont;
  font-weight: $FontWeight__Normal !important;
}

.disabled:not(.disabledReadable) > .lsFormFieldLabel {
  color: $Color__Concrete !important;
}

.FormField--Error > .lsFormFieldLabel {
  color: $Color__Rose !important;
}

.required:not(.disabled) > .lsFormFieldLabel::after {
  color: $Color__Rose;
  content: ' *';
}
