.DisplayTable-HeaderCell {
  color: $white;
  background-color: $gray900 !important;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 3px 0 5px;
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;
  @include antialiasing();
  &.DisplayTable-HeaderCell {
    svg {
      font-size: 13px;
      margin-left: 5px;
    }
  }
  &.DisplayTable-HeaderIconCell {
    text-align: center;
    svg {
      font-size: 13px;
      margin: 0;
    }
  }
  &.DisplayTable-HeaderCell--Sortable {
    cursor: pointer;
  }
  &.DisplayTable-HeaderCell--Sortable:not(.DisplayTable-HeaderIconCell) {
    padding-left: 13px;
  }
  &.DisplayTable-HeaderCell--SortedBy,
  &.DisplayTable-HeaderCell--SortedByReverse {
    background-color: #444444;
  }
}
