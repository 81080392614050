@import "../../../../../main.scss";

.DatePickerPopup-Calendar-Day {
  color: $black;
  border: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 38px;
  height: 32px;
  margin: 0;

  // stylelint-disable-next-line
  &:focus {
    outline: none;
  }

  &.DatePickerPopup-Calendar-Day--DifferentMonth {
    color: $gray300;
  }

  // We need to have a more specific selector than when the button is just
  // focused and not selected, hence the complex selector here
  &.DatePickerPopup-Calendar-Day--Selectable.DatePickerPopup-Calendar-Day--PickingDay.DatePickerPopup-Calendar-Day--Selected,
  &.DatePickerPopup-Calendar-Day--Selectable.DatePickerPopup-Calendar-Day--PickingDay.DatePickerPopup-Calendar-Day--Selected:focus,
  &.DatePickerPopup-Calendar-Day--Selectable.DatePickerPopup-Calendar-Day--PickingWeek.DatePickerPopup-Calendar-Day--WeekSelected,
  &.DatePickerPopup-Calendar-Day--Selectable.DatePickerPopup-Calendar-Day--PickingWeek.DatePickerPopup-Calendar-Day--WeekSelected:focus {
    background-color: $secondaryBlue;
    color: $white;
    font-weight: bold;
    border-radius: 100%;
    opacity: 1;
  }

  &.DatePickerPopup-Calendar-Day--Today:not(:hover):not(:focus) {
    background-color: $blue100;
    color: $primaryGreen;
    font-weight: bold;
    &::before {
      content: "";
      background-color: $primaryGreen;
      position: absolute;
      height: 4px;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  &.DatePickerPopup-Calendar-Day--Selectable {
    &:hover,
    &:focus {
      cursor: pointer;
      background-color: $blue700;
      opacity: 1;
    }

    &.DatePickerPopup-Calendar-Day--Selected.DatePickerPopup-Calendar-Day--PickingWeek {
      &:hover,
      &:focus {
        background-color: $secondaryBlue;
      }
    }
  }

  &.DatePickerPopup-Calendar-Day--PickingDay {
    width: 32px;
    margin: 0 3px;
    border-radius: 100%;
    &.DatePickerPopup-Calendar-Day--Today:not(:hover):not(:focus) {
      border-radius: 0%;
      margin: 0;
      padding: 0 3px;
    }
  }
}
