@import "../../main.scss";

.NotFound {
  height: 100%;
  width: 100%;
  padding: 16px;
  &__Header {
    margin-top: 4px;
  }

  &__Message {
    width: 400px;
    margin-top: 16px;
    margin-bottom: 25px;
  }
}
