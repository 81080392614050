.calendarMonth {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;

  &__Week {
    display: grid;
    grid-template-columns: repeat(7, minmax($minDayWidth, 1fr));
    grid-template-rows: 100%;
    grid-column-gap: 2px;
    background: $white;
    border-bottom: 2px solid $gray400;

    &--WithDividers {
      padding-right: 3px; //to offset the space created by the border
    }
  }

  &__DayName {
    background: $white;
    padding-bottom: 7px;
    text-align: left;

    &--Weekend {
      color: $gray400;
    }
  }

  &__Days {
    display: grid;
    height: 100%;
    background: $gray400;

    &--WithDividers {
      border-right: 3px solid $gray500;
    }

    &--sixDays {
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      grid-template-columns: repeat(7, minmax($minDayWidth, 1fr));
      grid-template-rows: repeat(6, minmax($minDaySize, 1fr));
    }

    &--fiveDays {
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      grid-template-columns: repeat(7, minmax($minDayWidth, 1fr));
      grid-template-rows: repeat(5, minmax($minDaySize, 1fr));
    }
  }
}
