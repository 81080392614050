@import '../../main.scss';

.Summary {
  width: 100%;
  .SummaryField {
    margin-right: 56px;
    margin-bottom: 12px;
  }

  &__Row {
    display: flex;
    flex-direction: row;
  }

  &--sections {
    column-width: 240px;
    column-gap: 56px;
  }
  .SectionWrapper {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
  &__Section {
    width: 100%;
    max-width: 240px;
    display: inline-block;
    border-top: 1px solid #54a080;
    padding-top: 4px;
    .lsHeader {
      font-weight: normal;
      margin-bottom: 16px;
    }
  }

  hr {
    width: 100%;
    border-width: 1px 0px 0px 0px;
    border-color: $Color__RobinLight1;
    // TODO: Confirm margins with UX
    margin-bottom: 12px;
    &:last-child {
      display: none;
    }
  }
}

.SummaryField {
  &__Label {
    font-size: 11px;
    color: $grayBlue600;
    margin: 0 0 4px 0;
  }
  &__Value {
    color: $grayBlue800;
    font-weight: 500;
    margin: 0;
  }

  .FieldWithPopup {
    display: flex;
    .SummaryPopup {
      &__Trigger {
        margin-left: 4px;
        align-self: center;
      }
    }
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  div:not(:last-child) {
    margin-bottom: 12px;
  }
  .SummaryPopup {
    &__Trigger {
      font-size: 12px;
      align-self: flex-end;
    }
  }

  &__HelpText {
    color: $Color__Steel;
    font-size: $FontSize__HelperText;
    margin-top: 2px;
  }
}

.SummaryPopup {
  &--dark {
    background-color: $darkBlue700 !important;
    &::before {
      background-color: $darkBlue700 !important;
    }
    .SummaryField {
      &__Label {
        color: #ffc300;
      }
      &__Value {
        color: $white;
      }
    }
    p {
      color: $white;
    }
  }
}

.SummaryLongText__Modal {
  .SummaryField {
    max-height: 250px;
    overflow-y: auto;
  }
}
