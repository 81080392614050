.IconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* TODO: Fix bug - right now all icons & icon buttons renders to 16px */
svg.Icon {
  font-size: 16px;
}
