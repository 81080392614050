@import '../../../main.scss';

.ui.button.IconButton {
  cursor: pointer;
  @include setFont;
  @include noSelect();
  font-size: 20px;
  background: transparent;
  padding: 0;

  &-Centered {
    display: flex;
    vertical-align: center;
    justify-content: center;
  }
  &.selected {
    font-weight: bold;
  }
  &.disabled {
    color: $gray200;
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
  &:not(.disabled) {
    color: $primaryGreen;
    @include clickAnimation();
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}
