@import "../../main.scss";

.InfoPopup {
  box-shadow: 0 2px 4px 0 rgba($black, 0.3) !important;
  color: $white;
  background-color: $black;
  &__Svg {
    width: 38px !important;
    color: $gray600;
    svg {
      height: 18px;
    }
  }
}
